$hsWidth: 60px;
$hsRatio: $hsWidth / 60px;
$anim-height: $hsWidth * 1.4;
$anim-steps: 40;
$anim-width: $anim-height * $anim-steps;

.scene-hotspot {
  width: $hsWidth;
  height: $hsWidth;
  position: relative;
  cursor: pointer;
  // pointer-events: none;
  // transform: translate(50%, -50%);
  &.z-index-99 {
    z-index: 99;
  }

  > .s-animation {
    position: absolute;
    height: $hsWidth * 1;
    width: $hsWidth * 1;
    top: $hsWidth * 0;
    left: $hsWidth * 0;
    pointer-events: none;

    &.position-unset {
      position: unset;
    }
  }

  > .s-info {
    position: absolute;
    height: $hsWidth;
    width: $hsWidth / 2;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    transition: none;

    .s-info-title,
    .s-info-distance {
      position: absolute;
      padding-left: 42px * $hsRatio;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        background-repeat: no-repeat;
        background-position: right top;
        background-size: auto 100%;
        width: 42px * $hsRatio;
        height: 100%;
      }

      p {
        margin: 0;
        font-family: var(--hotspot-font);
        // max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        // text-overflow: ellipsis;
      }
    }

    .s-info-distance {
      display: none !important;
    }

    .s-info-title {
      top: 0;
      left: 100%;
      height: $hsWidth / 2;
      padding-left: 0;

      p {
        line-height: $hsWidth / 2;
        background-color: rgba(255, 255, 255, 0.8);
        color: black;
        font-size: 14px;
        text-transform: uppercase;
        padding-right: 12px;
        padding-left: 20px;
        border-radius: 0 16px 16px 0;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1),
          1px -1px 1px rgba(0, 0, 0, 0.1);
      }
    }

    &-air_balloon-info_balloon-info {
      width: 100%;
    }

    .air-balloon-title {
      top: -70%;
      left: 50%;
      transform: translateX(-50%);
      p {
        width: auto;
        text-align: center;
        border-radius: 16px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .s-info-title {
      &_aacorp {
        top: -45% !important;
        p {
          border-radius: 16px;
          font-size: 14px;
          padding-right: 15px;
          padding-left: 15px;
          line-height: 20px;
          max-width: unset;
        }
      }

      &_longtitle {
        p {
          max-width: unset;
        }
      }

      .hpTwin {
        text-align: left;
        padding: 5px 19px;
        transform: translateY(-25px);
        line-height: 21px;
      }
    }

    &-air_balloon-info {
      width: 100%;
    }

    // .s-info-distance {
    //   left: 100%;
    //   bottom: 0;
    //   height: $hsWidth / 2 - 2px;
    //   opacity: 0.7;

    //   &:before {
    //     bottom: 0;
    //     background-image: url('./bg-black.png');
    //   }

    //   p {
    //     line-height: $hsWidth / 2 - 2px;
    //     background-color: black;
    //     color: white;
    //     font-size: 14px;
    //     font-weight: 700;
    //     padding: 0 10px;
    //   }
    // }
  }

  &-air__balloon {
    transform: translate(-1px, -50px);
    .air-balloon-square {
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: white;
      transform: rotate(45deg);
      bottom: -6px;
      left: 25%;
    }
    img {
      box-shadow: none !important;
      border-width: 2px !important;
    }
  }

  > .s-icon,
  .hotspot_icon_custom {
    position: absolute;
    width: $hsWidth;
    height: $hsWidth;
    top: 0;
    left: 0;
    z-index: 3;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    img {
      display: block;
      opacity: 1;
      box-shadow: none;
      background: transparent;
      border-radius: 0;
      border: none;
      object-fit: contain;
    }

    .icon-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .flicked-arrow-icon {
      position: relative;
      height: 40px;
    }
  }

  img {
    position: absolute;
    width: $hsWidth;
    height: $hsWidth;
    z-index: 4;
    display: none;
    opacity: 0;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    border: 1px solid white;
  }

  &-aacorp {
    width: 50px;
    height: 50px;
    img {
      height: 50px;
      width: 50px;
    }
    .air-balloon-square {
      height: 20px;
      width: 20px;
      bottom: 5px;
    }
  }

  &:before,
  &:after {
    display: none;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 3;
  }

  &:before {
    opacity: 1;
    background-image: url('./default-preview-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:after {
    opacity: 0.5;
    background-color: white;
    z-index: 3;
  }

  > .s-visited {
    position: absolute;
    width: $hsWidth * 0.3;
    height: $hsWidth * 0.3;
    top: $hsWidth * 0.1;
    right: $hsWidth * 0.1;
    z-index: 5;
  }

  &.hovering {
    img {
      opacity: 1;
      display: block;
    }

    &:before,
    &:after {
      display: block;
    }

    > .s-info {
      opacity: 1;
    }

    > .s-visited {
      opacity: 0;
    }
  }
}

@-webkit-keyframes circling {
  from {
    background-position: 0px;
  }
  to {
    background-position: -$anim-width;
  }
}

@-moz-keyframes circling {
  from {
    background-position: 0px;
  }
  to {
    background-position: -$anim-width;
  }
}

@-ms-keyframes circling {
  from {
    background-position: 0px;
  }
  to {
    background-position: -$anim-width;
  }
}

@-o-keyframes circling {
  from {
    background-position: 0px;
  }
  to {
    background-position: -$anim-width;
  }
}

@keyframes circling {
  from {
    background-position: 0px;
  }
  to {
    background-position: -$anim-width;
  }
}
