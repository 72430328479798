.mbh {
  &-infotag {
    position: fixed;
    bottom: 70px;
    right: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px;
    border-radius: 15px;
    border-bottom-right-radius: 0;
    transition: 0.3s ease;
    margin-left: 15px;

    &.hide {
      display: none;

      // @media (max-width: 767px) {
      //   display: block;
      //   height: 0;
      // }
    }
  }

  &-infotag--title {
    font-size: 20px;
    color: white;
    margin-bottom: 10px;
  }

  &-infotag--text {
    font-size: 14px;
    color: white;
    line-height: 1.5;
  }

  &-infotag--btn {
    display: none;
    position: absolute;
    top: -8px;
    right: -8px;
    color: white;
    width: 20px;
    height: 20px;
    line-height: 0.7;
    border-radius: 50%;
    border: none;
    transition: 0.3s ease;
    background-color: rgb(158, 158, 158);
    cursor: pointer;

    @media (min-width: 768px) {
      display: block;
    }

    &:hover {
      background-color: #03a5c6;
    }
  }

  &-infotag--mobile-btn {
    display: flex;
    position: fixed;
    right: 15px;
    bottom: 15px;
    height: 30px;
    width: 30px;
    color: white;
    border-radius: 50%;
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.3s ease;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .close-icon {
      width: 18px;
      height: 18px;
      path {
        fill: #fff;
      }
    }
  }

  &-infotag--mobile-img {
    height: 100%;
    width: 100%;
  }

  &-infotag--mobile-arrow {
    position: absolute;
    top: -25px;
    left: -2px;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid rgba(0, 0, 0, 0.5);
  }
}
