.mobi-controller-container {
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 1;
  // background-color: #00000061;
  .controller-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .controller-list-item {
      position: relative;
      cursor: pointer;
      transition: 0.4s;
      border-radius: 4px;
      .controller-list-item-popover {
        position: absolute;
        top: 50%;
        transform: translate(10%, -50%);
        right: 100%;
        display: flex;
        align-items: center;
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;
        .popover-content {
          border-radius: 4px;
          background-color: #000000b0;
          padding: 8px 15px;
          color: #e9e9e9;
          font-size: 14px;
          width: max-content;
        }
        &::after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-left: 6px solid #000000b0;
          border-bottom: 6px solid transparent;
        }
      }
      .icon {
        width: 45px;
        height: 45px;
        padding: 10px;
        path {
          fill: #fff;
        }
      }
      &:hover {
        .controller-list-item-popover {
          opacity: 1;
          visibility: visible;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

.mobi-controller--menu {
  position: fixed;
  top: 15px;
  right: 50%;
  transform: translateX(50%);
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);

  &-title {
    font-size: 18px;
    color: white;
    margin-bottom: 5px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    @media (max-width: 767px) {
      gap: 10px;
    }
  }

  &-item {
    padding: 5px 15px;
    transition: 0.3s ease;
    cursor: pointer;
    background-color: rgba(98, 88, 88, 0.5);
    border-radius: 5px;

    &:hover,
    &.active {
      background-color: rgba($color: #03a5c6, $alpha: 1);
    }

    p {
      color: white;
      font-size: 14px;
      font-weight: 500;
    }

    @media (min-width: 768px) {
      padding: 5px 15px;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
